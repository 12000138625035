const BikeSVG = (props) => (
    <svg
        xmlSpace="preserve"
        viewBox="0 0 100 100"
        y={0}
        x={0}
        xmlns="http://www.w3.org/2000/svg"
        style={{
            height: "auto",
            width: "100%",
            background: "#f1f2f3 0 0",
            shapeRendering: "auto",
        }}

        {...props}
    >
        <g
            style={{
                transformOrigin: "50% 50% 0",
                transform: "rotate(0deg) scale(.8)",
            }}
            className="ldl-scale"
        >
            <g className="ldl-ani">
                <g className="ldl-layer">
                    <g className="ldl-ani">
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.625s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <circle
                                strokeMiterlimit={10}
                                strokeWidth={3.855}
                                stroke="#333"
                                fill="#fff"
                                r={13.6}
                                cy={61.7}
                                cx={21.1}
                                style={{
                                    fill: "#496A81",
                                    stroke: "#fc9520",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.694444s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <circle
                                fill="#e0e0e0"
                                r={5}
                                cy={61.7}
                                cx={21.1}
                                style={{
                                    fill: "#2B3A67",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                    </g>
                </g>
                <g className="ldl-layer">
                    <g className="ldl-ani">
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.763889s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <circle
                                strokeMiterlimit={10}
                                strokeWidth={3.855}
                                stroke="#333"
                                fill="#fff"
                                r={13.6}
                                cy={61.7}
                                cx={78.9}
                                style={{
                                    fill: "#496A81",
                                    stroke: "#fc9520",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.833333s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <circle
                                fill="#e0e0e0"
                                r={5}
                                cy={61.7}
                                cx={78.9}
                                style={{
                                    fill: "#2B3A67",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                    </g>
                </g>
                <g className="ldl-layer">
                    <g className="ldl-ani">
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.902778s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <path
                                fill="#77a4bd"
                                d="M80.3 61 63.7 24.1c-.3-.8-1.3-1.1-2-.8-.8.3-1.1 1.3-.8 2l6.3 14H37.5L35 33.7 32.2 35l2.8 6.2-14.2 18.4c-.5.7-.6 1.6-.2 2.3.4.8 1.1 1.2 2 1.2h23.3l1-.4 22.3-19.1 8.3 18.5c.3.6.8.9 1.4.9.2 0 .4 0 .6-.1.8-.2 1.2-1.1.8-1.9zM36.4 44.4l7.1 15.8H24.3l12.1-15.8zm10 14.8-7.5-16.7h27L46.4 59.2z"
                                style={{
                                    fill: "#2B3A67",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -.972222s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <path
                                d="M28.9 34.4h10.4"
                                strokeMiterlimit={10}
                                strokeLinejoin="bevel"
                                strokeLinecap="round"
                                strokeWidth={3.084}
                                stroke="#c33737"
                                fill="none"
                                style={{
                                    stroke: "#fc9520",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -1.04167s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <circle
                                strokeMiterlimit={10}
                                strokeWidth={2.5}
                                stroke="#77a4bd"
                                fill="#a0c8d7"
                                r={6.5}
                                cy={61}
                                cx={45.5}
                                style={{
                                    fill: "#66999B",
                                    stroke: "#2B3A67",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                        <g
                            style={{
                                transformOrigin: "50px 50px 0",
                                transform: "translate(-1px)",
                                animation:
                                    "1.11111s linear -1.11111s infinite normal forwards running wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f",
                            }}
                            className="ldl-layer"
                        >
                            <path
                                d="M62.3 24.8h9.1"
                                strokeMiterlimit={10}
                                strokeLinejoin="bevel"
                                strokeLinecap="round"
                                strokeWidth={3.084}
                                stroke="#c33737"
                                fill="none"
                                style={{
                                    stroke: "#fc9520",
                                }}
                                className="ldl-ani"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <style id="wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f">
            {
                "@keyframes wander-h-f87635cd-e757-4a71-9327-b5839fbfee6f{0%{animation-timing-function:cubic-bezier(.6599,.1803,.8238,.6733);transform:translate(-1px,0)}10%{animation-timing-function:cubic-bezier(.4126,.3294,.7102,.6652);transform:translate(-.795px,0)}17.5%{animation-timing-function:cubic-bezier(.3486,.3328,.6192,.6682);transform:translate(-.438px,0)}35.1%{animation-timing-function:cubic-bezier(.2708,.3048,.6411,.6958);transform:translate(.5760000000000001px,0)}40.1%{animation-timing-function:cubic-bezier(.1731,.3255,.3395,.8291);transform:translate(.799px,0)}50.1%{animation-timing-function:cubic-bezier(.6576,.1906,.8206,.6689);transform:translate(1px,0)}60.1%{animation-timing-function:cubic-bezier(.4092,.3294,.7101,.6653);transform:translate(.793px,0)}67.6%{animation-timing-function:cubic-bezier(.3528,.3327,.616,.6682);transform:translate(.437px,0)}85%{animation-timing-function:cubic-bezier(.2925,.3308,.6163,.6733);transform:translate(-.5740000000000001px,0)}90%{animation-timing-function:cubic-bezier(.1781,.33,.3368,.8179);transform:translate(-.796px,0)}to{transform:translate(-1px,0)}}"
            }
        </style>
    </svg>
)

export default BikeSVG

// BikeIcon from here: https://loading.io/icon/5c9ekv
// Converted to JSX here: https://react-svgr.com/playground/
